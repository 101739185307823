@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;600;700;900&display=swap');

@layer base {
  .container-md {
    @apply mx-auto max-w-[95vw] md:max-w-7xl px-4 sm:px-6 lg:px-8;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
  }

  .title {
    @apply text-4xl mb-4 font-semibold text-slate-800 ;
  }

  p {
    @apply text-slate-700 text-lg leading-relaxed;
  }

  a {
    @apply text-blue-600 underline underline-offset-4 hover:text-blue-800 transition-all;
  }

  .input {
    @apply w-full lg:w-[400px] max-w-full mt-2 px-4 py-2 block rounded-md border-gray-300 shadow focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500;
  }

}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner-gradient {
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  border-radius: 25px;

  transition: opacity .25s ease-out;
}

.active {
  font-weight: 600;
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}